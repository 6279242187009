/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
 import "react-app-polyfill/ie11";
 import "react-app-polyfill/stable";
 import React, {Component} from "react"
 import propTypes from "prop-types"
 import { Router, createHistory} from "@reach/router";
 import {
    InstantSearch,
    Configure,
    connectRefinementList
  } from 'react-instantsearch-dom';
  import algoliasearch from 'algoliasearch';
  import qs from 'qs';

 import 'bootstrap/dist/css/bootstrap.min.css';
 import "../../styles/main.scss";
 
 // Include only the reset
 import 'instantsearch.css/themes/reset.css';

 const VirtualRefinementList = connectRefinementList(() => null);
 const DEBOUNCE_TIME = 200;
 const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY);
 
 const createURL = state => `?${qs.stringify(state)}`;
 
 const searchStateToUrl = (props, searchState) =>
   searchState ? `/search/${createURL(searchState)}` : '';
 
 const urlToSearchState = location => qs.parse(location.search.slice(1));

const history = typeof window !== `undefined` ? createHistory(window) : null

class SiteRoutes extends Component { 
    state = {
        searchState: urlToSearchState(this.props.location),
        lastLocation: this.props.location,
    };
      
    static getDerivedStateFromProps(props, state) {
    if (props.location !== state.lastLocation) {
        return {
        searchState: urlToSearchState(props.location),
        lastLocation: props.location,
        };
    }

    return null;
    }
    
    onSearchStateChange = searchState => {
        clearTimeout(this.debouncedSetState);
    
        this.debouncedSetState = setTimeout(() => {
          history.navigate(
            searchStateToUrl(this.props, searchState), 
            searchState
          );
        }, DEBOUNCE_TIME);
    
        this.setState({ searchState });
    };

  render(){
    const { searchState } = this.state;
    return (
      <>
        <InstantSearch
          indexName="BH_Location" 
          searchClient={searchClient}
          searchState={searchState}
          onSearchStateChange={this.onSearchStateChange}
          createURL={createURL}
        >
        <Configure
          hitsPerPage={40}
        />
        <VirtualRefinementList attribute="services.levelCare.name" />
        <VirtualRefinementList attribute="specialty" />
            <Router>
                {React.cloneElement(this.props.children, {searchClient: searchClient, searchState: searchState, onSearchStateChange: this.onSearchStateChange})}
            </Router>
        </InstantSearch>
      </>
     )
   }
 }

Router.propTypes = {
  children: propTypes.node.isRequired,
}

export default SiteRoutes;